import { useEffect } from 'react';
import { useWS } from './WS';
import * as uuid from 'uuid';

const createEvent = ({ action, type, subscriptionKey }) => ({
  action,
  id: uuid.v4(),
  timestamp: Date.now().toString(),
  subscriptionType: type,
  subscriptionKey,
  expiresAt: '',
});

export const getSubscription = (list) => {
  return list.join(':');
};

export const useSubscription = ({ type, keys, onMessage, onEcho = null, enabled = true }) => {
  const subscriptionKey = getSubscription([type, ...keys]);
  const ws = useWS();

  const send = (action, data = {}) => {
    const event = createEvent({ action, type, subscriptionKey });
    ws.send(event, data);
  };

  useEffect(() => {
    if (enabled && ws.status === 'connected') {
      ws.add(subscriptionKey, onMessage, onEcho);
      send('subscribe');
      return () => {
        send('unsubscribe');
        ws.remove(subscriptionKey, onMessage, onEcho);
      };
    }
  }, [enabled, ws.status]);

  return { send };
};
