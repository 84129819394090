import { Children, cloneElement } from 'react';
import { useThemedProps } from '../useThemedProps';
import { container } from '../shared-styles';
import { colors } from '../styles';
import { Text, textTheme } from './text';
import { css, cx } from '@emotion/css';
import { BriteLoader } from './brite-loader';
import { Box } from './box';

// EXAMPLE:
//
// <Table>
//   <Column
//      width="40%"
//      columnHeader="Name"
//      headerCss={` css-styles-here `}
//   >
//      {(data) => (<div>{data?.Name}</div>)}
//   </Column>
// </Table>
//

const tableTheme = {
  default: `
    width: 100%;
    ${textTheme.label}
    border-spacing: 0;
    tr {
      text-align: left;
    }
    .table-row {
      td {
        padding: 16px;
        border-bottom: 1px solid ${colors.gray[300]};
      }
    }
    .table-header {
      th {
        padding: 16px;
        border-bottom: 1px solid ${colors.gray[300]};
      }
    }
  `,
  hoverRows: `
    .table-row {
      :hover {
        ${container.hover}
      }
    }
  `,
};

export const Column = ({ data, children, css: cssString = '' }) => {
  return (
    <td
      className={cx(
        'table-cell',
        css`
          ${cssString}
        `
      )}
    >
      {children(data)}
    </td>
  );
};

export const Table = ({
  list,
  children,
  onHeaderClick,
  onRowClick,
  isLoading = false,
  emptyMessage = 'No data found.',
  ...rest
}) => {
  const props = useThemedProps(rest, tableTheme);
  const handleRowClick = (e, row) => {
    e.stopPropagation();
    onRowClick?.(row);
  };

  const handleHeaderClick = (e, data) => {
    e.stopPropagation();
    onHeaderClick?.(data);
  };

  const filteredChildren = children.filter((child) => !!child);

  return (
    <>
      <table {...props}>
        <colgroup>
          {Children.map(filteredChildren, (child) => (
            <col style={{ width: child.width, maxWidth: child.width }} />
          ))}
        </colgroup>
        <thead>
          <tr className="table-header">
            {Children.map(filteredChildren, (child, idx) => {
              return (
                <th
                  onClick={(e) =>
                    handleHeaderClick(e, {
                      columnHeader: child.props.columnHeader,
                      columnKey: child.props.columnKey,
                      idx,
                    })
                  }
                  className={css`
                    cursor: pointer;
                    text-align: left;
                    ${child?.props?.headerCss}
                  `}
                >
                  {child.props.columnHeader}
                </th>
              );
            })}
          </tr>
        </thead>
        {isLoading ? null : (
          <tbody>
            {list.map((rowData) => (
              <tr className="table-row" onClick={(e) => handleRowClick(e, rowData)}>
                {Children.map(filteredChildren, (child) => cloneElement(child, { data: rowData }))}
              </tr>
            ))}
          </tbody>
        )}
      </table>
      {isLoading ? (
        <Box
          css={`
            padding: 24px;
            width: 100%;
          `}
          flex="center"
        >
          <BriteLoader overlay={false} size={64} weight={8} />
        </Box>
      ) : !list?.length ? (
        <Text>{emptyMessage}</Text>
      ) : null}
    </>
  );
};
